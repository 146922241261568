import React from "react";
import Navbar from '../../commonComponents/navbar'
export default function StepperHeader() {
  return (
    <div>
      <Navbar color="#3f51b5" />
    </div>
  );
}

