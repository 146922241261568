import React, { useState, useRef } from "react";
import { style, useStyles } from "./style.js";
import { Button, CircularProgress, Grid, Paper } from "@material-ui/core";
import firebase from "firebase";
import BackButton from "../../../../commonComponents/buttons/BackButton/BackButton";
import { toast } from "react-toastify";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import UpdateIcon from "@material-ui/icons/Update";
import ButtonBase from "@material-ui/core/ButtonBase";
import ImageCrop from "../document/imageCrop/ImageCrop.js";
const Document = ({ state, setState, handleNext, handleBack }) => {
  const { idCardFront, idCardBack, profilephoto, email } = state;
  const [loader, setLoader] = useState(false);
  const [cropData, setCropData] = useState(null);
  const [idProfileImgLoading, setIsProfileImgLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const [currentHandler, setCurrentHandler] = useState(null);
  const cropperRef = useRef(null);
  var storageRef = firebase.storage().ref();
  const classes = useStyles();
  const images = [
    {
      url: idCardFront,
      title: "idCardFront",
      width: "30%",
      handler: (link) => setState({ idCardFront: link }),
    },
    {
      url: idCardBack,
      title: "idCardBack",
      width: "30%",
      handler: (link) => setState({ idCardBack: link }),
    },
    {
      url: profilephoto,
      title: "profilephoto",
      width: "30%",
      handler: (link) => setState({ profilephoto: link }),
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const MAX_SIZE = 1 * 1024 * 1024;

  const onFileChange = (e, handler) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.size > MAX_SIZE) {
        toast.error("Upload image less than 1MB");
        return;
      }
      setCurrentHandler(() => handler);
      setSrc(URL.createObjectURL(file));
      handleOpen();
    }
    e.target.value = "";
  };

  const onPreview = () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const canvas = cropper.getCanvas();
      setCropData(canvas.toDataURL());
    }
  };

  const onDone = () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const canvas = cropper.getCanvas();
      const dataUrl = canvas.toDataURL();
      uploadCroppedImage(dataUrl);
    }
    handleClose();
  };

  const uploadCroppedImage = (dataUrl, file) => {
    setLoader(true);
    const unique_id = parseInt(Math.random() * new Date().getTime());
    const fileName = `${email}-${unique_id}-${file ? file.name : 'defaultName'}.png`;
    let profileRef = storageRef.child("IDCards/" + fileName);
    profileRef.putString(dataUrl, "data_url").then((snapshot) => {
      snapshot.ref
        .getDownloadURL()
        .then((downloadURL) => {
          currentHandler(downloadURL);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    });
  };

  const validate = () => {
    if (!idCardFront) {
      toast.error("IDCard Front Required ");
    }
    if (!idCardBack) {
      toast.error("IDCard Back Required");
    }
    if (!profilephoto) {
      toast.error("Profilephoto Required");
    }

    if (!idCardFront || !idCardBack || !profilephoto) {
      return;
    } else return "ok";
  };

  const onSubmit = () => {
    if (validate() === "ok") {
      handleNext();
    }
  };

  return (
    <Paper style={style.paper}>
      <div style={style.documentInputs}>
        <Grid container spacing={3}>
          <div className={classes.root}>
            <Grid item lg={12} md={12} sm={12} xs={12} style={style.align}>
            {images.map((image) => {
              let { url, title, width, handler } = image;
              return (
                <ButtonBase
                  focusRipple
                  key={title}
                  className={classes.image}
                  focusVisibleClassName={classes.focusVisible}
                  style={{ width: width }}
                >
                  <span
                    className={classes.imageSrc}
                    style={{ backgroundImage: `url(${url})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                  />
                  <span className={classes.imageBackdrop} />
                  <span className={classes.imageButton}>
                    {url ? (
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.imageTitle}
                        startIcon={<UpdateIcon />}
                        component="label"
                      >
                        Update {title}
                        <input
                          type="file"
                          style={style.input}
                          hidden
                          onChange={(e) => onFileChange(e, handler)}
                        />
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.imageTitle}
                        startIcon={<CloudUploadIcon />}
                        component="label"
                      >
                        {title}
                        <input
                          type="file"
                          style={style.input}
                          hidden
                          onChange={(e) => onFileChange(e, handler)}
                          accept="image/*"
                        />
                      </Button>
                    )}
                    <span className={classes.imageMarked} />
                  </span>
                </ButtonBase>
              );
            })}
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          style={{ position: "relative", marginTop: "3%" }}
        >
          <BackButton
            variant="contained"
            color="primary"
            component="span"
            text="Back"
            handleBack={handleBack}
          />
          {loader ? (
            <CircularProgress style={style.NextButton} />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              style={style.NextButton}
            >
              Next
            </Button>
          )}
        </Grid>
      </div>

      <ImageCrop
        src={src}
        open={open}
        handleClose={handleClose}
        onDone={onDone}
        cropperRef={cropperRef}
        cropData={cropData}
        onPreview={onPreview}
      />
    </Paper>
  );
};

export default Document;
