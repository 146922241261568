import React, { useEffect } from "react";
import { style } from "./homeStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Buttons from "../../commonComponents/buttons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
function Home() {
  const matches = useMediaQuery("(min-width:960px)");

  return (
    <div>
      <Grid container style={style.bgImage}>
        <div style={style.overly}>
          <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
          <Grid
            item
            lg={8}
            md={8}
            sm={12}
            xs={12}
            style={matches ? style.alignment : style.alignment_Sm}
          >
            <Typography
              style={matches ? style.heading : style.heading_Sm}
              variant="h4"
            >
              Join Our Engineering Team
            </Typography>
            <Typography
              style={matches ? style.para : style.para_Sm}
              variant="subtitle1"
              gutterBottom
            >
              The Entire Team Techloset is thrilled to Welcome you Onboard. We
              hope you’ll do some Amazing work here!
            </Typography>
            <br />
            <Buttons title="On Boarding" text="#fff" to="/onBoarding" />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
        </div>
      </Grid>
    </div>
  );
}
export default Home;
