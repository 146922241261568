import React from 'react';
import { style } from './style';
import { Typography } from '@material-ui/core/';

function termCondition(props) {
  const classess = style();
  return (
    <div>
      <Typography variant="h6" className={classess.condition}>
        {props.title}
      </Typography>
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        className={classess.paragraph}
      >
        {props.children}
      </Typography>
    </div>
  );
}

export default termCondition;
