import React, { useEffect, useRef, useState } from "react";

import { style } from "./style.js";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import BackButton from "../../../../commonComponents/buttons/BackButton/BackButton";
import InputMask from "react-input-mask";
import {
  accountTypes,
  countries,
  cityOfBirth,
  typesOfAddress,
  dualNationality,
  kinRelation,
  contract,
  PEP,
} from "./constant.js";
import { toast } from "react-toastify";

const filter = createFilterOptions();

const BankDetail = ({
  state,
  setState,
  handleNext,
  handleBack,
  selectedPaper,
  setSelectedPaper,
  supportedBanks,
}) => {
  const {
    accountName,
    accountHolderName,
    accountBranchCode,
    accountIbn,
    accountNumber,
    accountType,
    nationality,
    dualNational,
    countryOfBirth,
    cityofBirth,
    countryOfResidence,
    nicIssueDate,
    nicExpiryDate,
    customerVisuallyImpaired,
    motherMaidenName,
    citizenTaxRasidenceOtherThanPakistan,
    husbandName,
    pep,
    typeOfAddress,
    permanentCity,
    nextOfKinName,
    nextOfKinRelation,
    nextOfKinPhoneNumber,
    employmentType,
  } = state;
  
  const dateOfIssueRef = useRef(null);
  const dateOfExpiryRef = useRef(null);
  const [inpuIssueType, setInpuIssueType] = useState("text");
  const [inputExpiryType, setInputExpiryType] = useState("text");

  const onSubmit = () => {
    if (selectedPaper === "first") {
      if (employmentType === "") {
        toast.error("Please Select Employment Type");
      } else if (
        employmentType === "Bootcamp" ||
        employmentType === "Internship"
      ) {
        handleNext();
      } else {
        setSelectedPaper("second");
      }
    }

    if (selectedPaper === "second") {
      const validate = validateBankAccount();
      if (validate === "ok") {
        handleNext();
      }
    }
    if (selectedPaper === "third") {
      const validate = validateCreateAccount();
      if (validate === "ok") {
        handleNext();
      }
    }
  };

  const validateCreateAccount = () => {
    if (!nationality) {
      toast.error("Please Select Nationality");
    }
    if (!dualNational) {
      toast.error("Please Select Dual Nationality");
    }
    if (!countryOfBirth) {
      toast.error("Please Select Country of Birth");
    }
    if (!cityofBirth) {
      toast.error("Please Select City of Birth");
    }
    if (!countryOfResidence) {
      toast.error("Please Select Country of Residence");
    }
    if (!customerVisuallyImpaired) {
      toast.error("Please Select Customer Visually Impaired");
    }
    if (!nicIssueDate) {
      toast.error("Please Enter CNIC Issue Date");
    }
    if (!nicExpiryDate) {
      toast.error("Please Enter CNIC Expiry Date");
    }
    if (!motherMaidenName) {
      toast.error("Please Enter Mother Maiden Name");
    }
    if (!citizenTaxRasidenceOtherThanPakistan) {
      toast.error("Please Select Citizen Tax Residence Other Than Pakistan");
    }
    if (!pep) {
      toast.error("Please Select PEP");
    }
    if (!typeOfAddress) {
      toast.error("Please Select Type of Address");
    }
    if (!permanentCity) {
      toast.error("Please Select Permanent City");
    }
    if (!nextOfKinName) {
      toast.error("Please Enter Next of Kin Name");
    }
    if (!nextOfKinRelation) {
      toast.error("Please Select Next of Kin Relation");
    }
    if (!nextOfKinPhoneNumber) {
      toast.error("Please Enter Next of Kin Phone Number");
    }

    if (
      !nationality ||
      !dualNational ||
      !countryOfBirth ||
      !cityofBirth ||
      !countryOfResidence ||
      !customerVisuallyImpaired ||
      !nicIssueDate ||
      !nicExpiryDate ||
      !motherMaidenName ||
      !citizenTaxRasidenceOtherThanPakistan ||
      !pep ||
      !typeOfAddress ||
      !permanentCity ||
      !nextOfKinName ||
      !nextOfKinRelation ||
      !nextOfKinPhoneNumber
    ) {
      return;
    } else return "ok";
  };
  const validateBankAccount = () => {
    if (!accountName) {
      toast.error("Please Select Bank Name");
    }
    if (!accountHolderName) {
      toast.error("Please Enter Account Holder Name");
    }
    if (!accountBranchCode) {
      toast.error("Please Enter Branch Code");
    }
    if (!accountNumber) {
      toast.error("Please Enter Account Number");
    }
    if (!accountIbn) {
      toast.error("Please Enter IBAN Number");
    }
    if (!accountType) {
      toast.error("Please Select Account Type");
    }

    if (
      !accountName ||
      !accountHolderName ||
      !accountBranchCode ||
      !accountNumber ||
      !accountIbn ||
      !accountType
    ) {
      return;
    } else return "ok";
  };

  const selectAccountName = (val) => {
    setState({ accountName: val });
  };
  const selectAccountType = (val) => {
    setState({ accountType: val });
  };
  const setAccountHolderName = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 36) {
      setState({ accountHolderName: inputValue });
      toast.dismiss();
    } else {
      if (!toast.isActive("invalid-account-toastter")) {
        toast.error("Too much Lengthy Name", {
          toastId: "invalid-account-toastter",
        });
      }
    }
  };
  const setAccountNumber = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 36) {
      setState({ accountNumber: inputValue });
      toast.dismiss();
    } else {
      if (!toast.isActive("invalid-account-toast")) {
        toast.error("Max length for Account Number is 36", {
          toastId: "invalid-account-toast",
        });
      }
    }
  };
  const setAccountIbn = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 36) {
      setState({ accountIbn: inputValue });
    } else {
      if (!toast.isActive("invalid-account-toastt")) {
        toast.error("Max length for IBAN Number is 36", {
          toastId: "invalid IBAN toast",
        });
      }
    }
  };

  useEffect(() => {
    if (dateOfIssueRef?.current) {
      if (dateOfIssueRef?.current?.type === "date") {
        dateOfIssueRef?.current?.showPicker();
      }
    }
  }, [inpuIssueType]);

  useEffect(() => {
    if (dateOfExpiryRef?.current) {
      if (dateOfExpiryRef?.current?.type === "date") {
        dateOfExpiryRef?.current?.showPicker();
      }
    }
  }, [inputExpiryType]);
  return (
    <>
      <Paper style={style.paper} hidden={selectedPaper !== "first"}>
        <div style={style.basicInputs}>
          <Grid container spacing={3} justify="center">
            <Grid item md={4} xs={11} style={style.paragraphDiv}>
              <Typography variant="h5">I am onboarding for</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="center">
            <Grid item md={5} xs={11}>
              <Autocomplete
                value={employmentType}
                onChange={(event, newValue) => {
                  setState({ employmentType: newValue ? newValue : "" });
                }}
                options={contract}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Employment Type"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}></Grid>
          <Grid container spacing={3}></Grid>
          <Grid
            container
            spacing={3}
            justify="space-around"
            style={{ marginTop: "20px" }}
          >
            <Grid item spacing={3} md={4} xs={4}>
              <BackButton
                variant="contained"
                color="primary"
                component="span"
                text="Back"
                handleBack={handleBack}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={style.NextButton}
                onClick={onSubmit}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <Paper style={style.paper} hidden={selectedPaper !== "second"}>
        <div style={style.basicInputs}>
          <Grid container spacing={3} justify="center">
            <Grid item md={10} xs={11} style={style.paragraphDiv}>
              <Typography>
                Please insert your bank account details (we support only HBL
                Account) for payroll process, If you don't have an account, you
                can skip for now, and{" "}
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => setSelectedPaper("third")}
                >
                  Click for creating Bank Account
                </span>{" "}
                keep working on setup the new bank account by using Techloset
                hiring letter, and add your bank account details in
                myprofile.techloset.com later! Thank you.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="center">
            <Grid item md={5} xs={12}>
              <Autocomplete
                value={supportedBanks.find( (bank) => bank.bankABR === accountName)}
                onChange={(event, newValue) => {
                  selectAccountName(newValue ? newValue.bankABR : "");
                }}
                options={supportedBanks}
                getOptionLabel={(option) => option.bankName}
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="Bank Name"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <TextField
              required
                style={style.input}
                type="text"
                label="Account Holder Name"
                value={accountHolderName}
                onChange={setAccountHolderName}
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <TextField
              required
                style={style.input}
                label="Branch Code"
                type="number"
                min={0}
                value={accountBranchCode}
                onChange={(event) => {
                  setState({ accountBranchCode: event.target.value });
                }}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
              required
                style={style.input}
                label="Account Number"
                value={accountNumber}
                onChange={setAccountNumber}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
              required
                style={style.input}
                label="IBAN Number"
                value={accountIbn}
                onChange={setAccountIbn}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <Autocomplete
                value={accountType}
                onChange={(event, newValue) => {
                  selectAccountType(newValue ? newValue : "");
                }}
                options={accountTypes}
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="Account Type"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}></Grid>
          <Grid container spacing={3}></Grid>
          <Grid
            container
            spacing={3}
            justify="space-around"
            style={{ marginTop: "20px" }}
          >
            <Grid item spacing={3} md={4} xs={4}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={style.BackButton}
                onClick={() => setSelectedPaper("first")}
              >
                Back
              </Button>
            </Grid>
            <Grid
              item
              md={4}
              xs={4}
              // style={{ position: 'relative', marginTop: '20px' }}
            >
              <Button
                variant={"contained"}
                color={"primary"}
                style={style.NextButton}
                onClick={onSubmit}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <Paper style={style.paper} hidden={selectedPaper !== "third"}>
        <div style={style.basicInputs}>
          <Grid container spacing={3} justify="center">
            <Grid item md={10} xs={11} style={style.paragraphDiv}>
              <Typography>
                Welcome to the bank account setup page! If you already have an
                account with HBL,{" "}
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => setSelectedPaper("second")}
                >
                  Go Back
                </span>{" "}
                and fill the details, otherwise fill the form below to create a
                new bank account.
              </Typography>
            </Grid>
            <Grid item md={5} xs={12}>
              <Autocomplete
                value = {nationality}
                onChange={(event, newValue) => {
                  setState({ nationality: newValue ? newValue?.label : ''});
                }}
                options={countries}
                autoHighlight
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=""
                    />
                    {option.label} ({option.code})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="Nationality"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <Autocomplete
                value={dualNational}
                onChange={(event, newValue) => {
                  setState({ dualNational: newValue ? newValue : "" });
                }}
                options={dualNationality}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="Dual Nationality"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <Autocomplete
                value={countryOfBirth}
                onChange={(event, newValue) => {
                  setState({ countryOfBirth: newValue ? newValue?.label : "" });
                }}
                options={countries}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=""
                    />
                    {option?.label} ({option?.code})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="Country of Birth"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <Autocomplete
                value={cityofBirth}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setState({ cityofBirth: newValue });
                  } else if (newValue && newValue.inputValue) {
                    setState({ cityofBirth: newValue.inputValue });
                  } else {
                    setState({ cityofBirth: newValue ? newValue?.label : "" });
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option?.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      label: `Add "${inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={cityOfBirth}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option?.inputValue) {
                    return option?.inputValue;
                  }
                  return option?.label;
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="City of Birth"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <Autocomplete
                value={countryOfResidence}
                onChange={(event, newValue) => {
                  setState({
                    countryOfResidence: newValue ? newValue?.label : "",
                  });
                }}
                options={countries}
                autoHighlight
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=""
                    />
                    {option?.label} ({option?.code})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="Country of Residence"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <Autocomplete
                value={customerVisuallyImpaired}
                onChange={(event, newValue) => {
                  setState({
                    customerVisuallyImpaired: newValue ? newValue : "",
                  });
                }}
                autoHighlight
                options={dualNationality}
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="Customer Visually Impaired"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                style={style.inputDate}
                required
                inputRef={dateOfIssueRef}
                label="CNIC Issue Date"
                type={inpuIssueType}
                value={nicIssueDate}
                onFocus={() => {
                  setInpuIssueType("date");
                }}
                onBlur={() => {
                  setInpuIssueType("text");
                }}
                onChange={(event) => {
                  setState({ nicIssueDate: event.target.value });
                }}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                style={style.inputDate}
                required
                inputRef={dateOfExpiryRef}
                label="CNIC Expiry Date"
                type={inputExpiryType}
                onFocus={() => {
                  setInputExpiryType("date");
                }}
                onBlur={() => {
                  setInputExpiryType("text");
                }}
                value={nicExpiryDate}
                onChange={(event) => {
                  setState({ nicExpiryDate: event.target.value });
                }}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
              required
                style={style.input}
                label="Mother Maiden Name"
                value={motherMaidenName}
                onChange={(event) => {
                  setState({ motherMaidenName: event.target.value });
                }}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                style={style.input}
                label="Husband Name (Optional)"
                value={husbandName}
                onChange={(event) => {
                  setState({
                    husbandName: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <Autocomplete
                value={citizenTaxRasidenceOtherThanPakistan}
                onChange={(event, newValue) => {
                  setState({
                    citizenTaxRasidenceOtherThanPakistan: newValue
                      ? newValue
                      : "",
                  });
                }}
                autoHighlight
                options={dualNationality}
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="Citizen Tax Residence Other Than Pakistan"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <Autocomplete
                value={pep}
                onChange={(event, newValue) => {
                  setState({ pep: newValue ? newValue : "" });
                }}
                autoHighlight
                options={PEP}
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="PEP"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <Autocomplete
                value={typeOfAddress}
                onChange={(event, newValue) => {
                  setState({ typeOfAddress: newValue ? newValue : "" });
                }}
                autoHighlight
                options={typesOfAddress}
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="Types of Address"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <Autocomplete
                value={permanentCity}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setState({ permanentCity: newValue });
                  } else if (newValue && newValue.inputValue) {
                    setState({ permanentCity: newValue.inputValue });
                  } else {
                    setState({ permanentCity: newValue ? newValue.label : "" });
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      label: `Add "${inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={cityOfBirth}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.label;
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="Permanent City"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
              required
                style={style.input}
                label="Next of Kin Name"
                value={nextOfKinName}
                onChange={(event) => {
                  setState({ nextOfKinName: event.target.value });
                }}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <Autocomplete
                value={nextOfKinRelation}
                onChange={(event, newValue) => {
                  setState({
                    nextOfKinRelation: newValue ? newValue.label : "",
                  });
                }}
                options={kinRelation}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                  required
                    {...params}
                    label="Next of Kin Relation"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <InputMask
                maskChar={0}
                mask="9999-9999999"
                value={nextOfKinPhoneNumber}
                onChange={(e) =>
                  setState({ nextOfKinPhoneNumber: e.target.value })
                }
              >
                {(props) => (
                  <TextField
                  required
                    {...props}
                    style={style.input}
                    label="Kin Phone Number"
                    placeholder="Phone Number e.g 03xx-xxxxxxx"
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>
          <Grid container spacing={3}></Grid>
          <Grid container spacing={3}></Grid>
          <Grid
            container
            spacing={3}
            justify="space-around"
            style={{ marginTop: "20px" }}
          >
            <Grid item spacing={3} md={4} xs={4}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={style.BackButton}
                onClick={() => setSelectedPaper("second")}
              >
                Back
              </Button>
            </Grid>
            <Grid
              item
              md={4}
              xs={4}
              // style={{ position: 'relative', marginTop: '20px' }}
            >
              <Button
                variant={"contained"}
                color={"primary"}
                style={style.NextButton}
                onClick={onSubmit}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </>
  );
};

export default BankDetail;
