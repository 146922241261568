import { Button } from '@material-ui/core'
import React from 'react'
import { style } from './style.js';

export default function BackButton({ variant, color, onSubmit, text, handleBack }) {
  return (
    <Button
      variant={variant}
      color={color}
      style={style.BackButton}
      onClick={handleBack}
    >
      {text}
    </Button>
  )
}