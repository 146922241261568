export const style = {
  educationInputs: {
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  select: {
    width: '100%',
    border: "1px solid #d7dde2",
    borderRadius: "5px",
    padding: '10px',
    outline: 'none',
    color: 'grey',
    backgroundColor: 'rgb(255 255 255)'
  },
  paper: {
    padding: '10px',
  },
  label:{
    marginBottom: '10px'
  }
}
