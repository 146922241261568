import React from "react";
import Home from "../modules/home/Home";
import Thnx from "../modules/thanks";
import OnBoarding from "../modules/onboarding/OnBoarding";

import Navbar from "../commonComponents/navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import StepperFooter from "../commonComponents/stepperfooter";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Navbar />
          <Home />
        </Route>
        <Route path="/onboarding/:userEmail?/:userName?/:contractType?" exact>
          <OnBoarding />
        </Route>
        <Route path="/onboarding" exact>
          <OnBoarding />
        </Route>
        <Route path="/thanks">
          <Navbar color="#3f51b5" />
          <Thnx />
          <StepperFooter />
        </Route>
      </Switch>
    </Router>
  );
}
export default App;
