export const style = {
  disabledButton: {
    float: 'right',
    position: 'relative',
    right: 0,
    top: '50%',
  },
  basicInputs: {
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  input: {
    width: '100%',
    outline: 'none',
  },
  inputDate: {
    width: '100%',
    color: 'grey',
    outline: 'none',
  },
  select: {
    width: '100%',
    border: '1px solid #d7dde2',
    borderRadius: '5px',
    padding: '10px',
    // marginTop: '10px',
    outline: 'none',
    color: 'grey',
    backgroundColor: 'rgb(255 255 255)',
  },
  paper: {
    padding: '10px',
  },
  goback:{
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    cursor: 'pointer',
  },
  paragraphDiv: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  NextButton: {
    float: 'right',
    position: 'relative',
    right: 0,
    top: '50%',
  },
  BackButton: {
    float: 'left',
    position: 'relative',
    left: 0,
    top: '50%',
  },
};
