import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { style } from "./style.js";
import "./Agreement.css";

import {
  Checkbox,
  CircularProgress,
  Typography,
  Button,
} from "@material-ui/core/";
import TermsCondition from "./termCondition";
import BackButton from "../../../../commonComponents/buttons/BackButton/BackButton";
import NextButton from "../../../../commonComponents/buttons/Next/index.js";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import db from "../../../../config/firebase";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  heading: {
    textAlign: "center",
    fontWeight: 900,
    marginTop: "4%",
  },

  sigPad: {
    width: "80%",
    height: "200px",
    marginTop: "10px",
    marginLeft: "10px",
  },
  imagePreview: { width: "200px", height: "50px" },
}));

export default function Agreement({ state, setState, handleBack }) {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState("");
  const classes = useStyles();
  const { fullName, gender, sign, contractType, martialStatus } = state;
  const [checkBox, setcheckBox] = useState(false);
  const [sigPad, setSigPad] = useState(null);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  let currentDate =
    monthNames[d.getMonth()] + " " + d.getDate() + " " + d.getFullYear();
  const clear = () => {
    if (sigPad !== null) {
      sigPad.clear();
      setState({ sign: "" });
    }
  };
  const trim = () => {
    if (sigPad !== null) {
      if (sigPad.isEmpty()) {
        toast.error("Draw a signature First");
      } else {
        setState({
          sign: sigPad.getTrimmedCanvas().toDataURL("image/png"),
        });
      }
    }
  };
  const getContract = async (joiningDate, employeeName, gender) => {
    try {
      setLoading(true);
      const doc = await db.collection("contract").doc(contractType).get();
      if (doc?.exists) {
        const { contract, date } = doc.data();
        const updatedAt = moment(date).format("DD/MM/YYYY");
        let details = contract;
        details = details.replace("joiningDate", joiningDate);
        details = details.replaceAll("employeeName", employeeName);
        details = details.replaceAll("updatedAt", updatedAt);
        if (gender !== "Male") {
          if (martialStatus === "Married") {
            details = details.replaceAll("Mr", "Mrs");
          } else {
            details = details.replaceAll("Mr", "Ms");
          }
        }
        details = details.replace(
          "I accept the terms &amp; conditions imposed by Techloset Solutions (PVT) LTD.</p><h4>SIGNATURE:<br/></h4>",
          ""
        );
        setContract(details);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (contractType) getContract(currentDate, fullName, gender);
  }, [fullName, gender, contractType]);
  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : contract ? (
        <>
          <div
            className="updatedContract"
            dangerouslySetInnerHTML={{ __html: contract }}
          />
          <h4>
            <Checkbox
              onChange={(e) => setcheckBox(e.target.checked)}
              size="medium"
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            I accept the terms & conditions imposed by Techloset Solutions (PVT)
            LTD.
          </h4>
        </>
      ) : (
        <div className={classes.root}>
          <Typography variant="h6" gutterBottom className={classes.heading}>
            Employment Agreement
          </Typography>
          <TermsCondition>
            This agreement was made as of <b>​{currentDate}</b> between{" "}
            <b>Techloset Solutions (PVT) LTD​.</b> A corporation incorporated
            under the laws of Pakistan, and having its principal place of
            business at Kashmir pull, West Canal road,<b> Faisalabad </b> (the
            "Employer"); and{" "}
            <b>
              {gender === "Male" ? "Mr." : "Ms"} {fullName},{" "}
            </b>{" "}
            (the "Employee"). Whereas the employer desires to obtain the benefit
            of the services of the employee, and the employee desires to render
            such services on the terms and conditions set forth.
            <br />
            In consideration of the promises and other good and valuable
            consideration (the sufficiency and receipt of which are hereby
            acknowledged) the parties agree as follows:
          </TermsCondition>
          <TermsCondition title="Employment Terms & Conditions">
            It is not permissible to work on freelancing platforms while on
            duty. Using <b> Fiverr, Upwork, freelancer.com </b> or any other
            such site during office hours is prohibited. Collaborating with team
            members to work on personal projects is forbidden.
          </TermsCondition>
          <TermsCondition title=" Working Hours">
            Working hours are based on the normal operating hours which are from
            <b> 10:00 am –7:00 pm​, </b> six days a week{" "}
            <b>Monday to Saturday​.</b> The time from 2 pm to 3 pm is taken as a
            lunch break.
          </TermsCondition>
          <TermsCondition title="Probation Period">
            It is understood and agreed that the first <b> 3 months </b> of
            employment shall constitute a probationary period during which the
            employer may, in its absolute discretion, terminate the employee's
            employment, for any reason without notice or cause. The employee
            shall not be entitled to any benefits such as paid leaves, insurance
            during the probationary period.
          </TermsCondition>
          <TermsCondition title=" Leave Policy">
            All leaves (sick/casual/annual) must be requested from
            <b> myprofile.techloset.com </b>. Any leaves exceeding beyond the
            set quota shall be considered as unpaid/loss of pay. Sick leave is
            granted as required at the company's discretion. If the weekend
            falls within the sick leave period then Sunday will be counted
            otherwise only working days will be counted.
          </TermsCondition>
          <TermsCondition title=" Performance Reviews (Yearly Benefits)">
            The performance of the employee will be reviewed annually by the
            team lead, HR, PM, and based on the evaluation & assessment of the
            employee’s annual overall performance, behavior, attitude, work
            ethics, etc. and increment will be granted.
          </TermsCondition>
          <TermsCondition title="Resignation/​Termination">
            <b>
              A minimum of 3 months advance notice period is mandatory in case
              of resignation.
            </b>
            The approval of resignation will be subject to the{" "}
            <b> ongoing project </b>, work status, that was{" "}
            <b> assigned to the employee </b>. Failure to this shall result in
            the loss of pay/ remuneration.
            <br />
            <br />
            The employer may terminate the employment of the employee at any
            time in case the employee fails to respond to show-cause notice,
            poor performance, bad attitude, & based on other disciplinary
            reasons. The employee agrees to return any property that{" "}
            {gender === "Male" ? " he " : " she "} possesses of Techloset
            Solutions (PVT) LTD. at the time of termination.
          </TermsCondition>
          <TermsCondition title="Confidentiality​">
            During the course of your employment with Techloset Solutions (PVT)
            LTD., you will be entrusted with{" "}
            <b> confidential and proprietary </b>
            information. You agree that such information <b>
              {" "}
              will not be{" "}
            </b>{" "}
            released or divulged, whether directly or indirectly, unless
            authorized by company policy, required by law, or through the
            express written consent of the Techloset Solutions (PVT) LTD. Given
            under the hand of the proper officer with authority to give such
            consent. You are also{" "}
            <b> not to disclose your salary to your team members</b>. Techloset
            Solutions (PVT) LTD. Rigorously enforces this rule to ensure the
            privacy of individual staff members.
          </TermsCondition>
          <TermsCondition title="Projects Property">
            During the period of your employment you will work on different
            projects that are entitled to be TechloSet Solutions’ property. You
            agree that you shall not use any of those project links as an
            individual development portfolio. If this condition is breached,
            Strict actions legal action falling under the intellectual property
            law will be taken against you.
          </TermsCondition>
          <TermsCondition title="Declaration​">
            <b>
              {gender === "Male" ? " Mr. " : "Ms. "} {fullName}
            </b>{" "}
            hereby acknowledges and agrees that I would serve{" "}
            <b>Techloset Solutions (PVT) LTD.</b> For a period of ​12 months
            from the date of joining. ​I also agree, <b> failing </b> to which
            will result in paying an amount of one-month gross salary as a{" "}
            <b> penalty </b> as well as no <b> experience documents </b>{" "}
            (release letter, experience certificate, etc.) will be issued to me.
            <br />
            <br />I also divulge that I will be completely responsible for
            bearing the consequences or legal action (if required) in case of
            breaching any of the above-mentioned terms of services by Techloset
            Solution (PVT) LTD. <br />
            <br />
            I,{" "}
            <b>
              {gender === "Male" ? "Mr. " : "Ms. "} {fullName}
            </b>{" "}
            hereby also acknowledge that my{" "}
            <b> employment contract will be renewed yearly. </b>
            <br />
            <br />
            <Checkbox
              onChange={(e) => setcheckBox(e.target.checked)}
              size="medium"
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            I accept the terms & conditions imposed by Techloset Solutions (PVT)
            LTD.
          </TermsCondition>
        </div>
      )}
      {loading ? null : (
        <>
          <div>
            <h3> * Draw Your signature</h3>
            <SignatureCanvas
              backgroundColor="#F5F5F5"
              ref={(ref) => {
                setSigPad(ref);
              }}
              penColor="black"
              canvasProps={{
                className: classes.sigPad,
              }}
            />
            <br />

            <Button variant="contained" color="primary" onClick={() => clear()}>
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => trim()}
              style={{ marginLeft: "3px" }}
            >
              Save
            </Button>
            <br />
            {sign !== "" ? (
              <>
                <h3> Signature Preview </h3>
                <img src={sign} className={classes.imagePreview} />{" "}
              </>
            ) : null}
          </div>

          <div
            style={{
              marginBottom: "120px",
              marginTop: "20px",
              position: "relative",
            }}
          >
            <BackButton
              variant="contained"
              color="primary"
              component="span"
              text="Back"
              handleBack={handleBack}
            />
            {loader ? (
              <CircularProgress style={{ float: "right" }} />
            ) : (
              <NextButton
                variant="contained"
                color="primary"
                style={style.NextButton}
                text="Finish"
                state={state}
                setState={setState}
                loader={!checkBox || sign === ""}
                setLoader={setLoader}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
