import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  imageCropContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    // height: "65vh",
    padding: "2%",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: 24,
    p: 1,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      width: "90%",
    },
  },
  cropImageContainer: {
    width: "70%",
    height: "64vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cropModalImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    // margin: "5%",
  },
  previewContainer: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
      width: "100%",
    },
  },
  previewImage: {
    width: "190px",
    height: "190px",
    borderRadius: "50%",
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      width: "130px",
      height: "130px",
      marginTop: 15,
    },
  },
  previewdummyImage: {
    width: "250px",
    height: "250px",
    borderRadius: "50%",
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      width: "170px",
      height: "170px",
      marginTop: 10,
    },
  },
  width: "90%",
}));

// logotypeContainer: {
//     backgroundColor: theme.palette.primary.main,
//     width: "60%",
//     height: "100%",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     [theme.breakpoints.down("md")]: {
//       width: "50%",
//     },
//     [theme.breakpoints.down("md")]: {
//       display: "none",
//     },
//   },
//   logotypeImage: {
//     width: 165,
//     marginBottom: theme.spacing(4),
//   },
//   logotypeText: {
//     color: "white",
//     fontWeight: 500,
//     fontSize: 84,
//     [theme.breakpoints.down("sm")]: {
//       fontSize: 48,
//     },
//   },
//   formContainer: {
//     width: "40%",
//     height: "100%",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     [theme.breakpoints.down("md")]: {
//       width: "50%",
//     },
//   },
