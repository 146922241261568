export const style = {
  main: {
    position: "fixed",
    textAlign: 'center',
    marginTop: '3%',
    backgroundColor: 'rgb(242, 243, 248)',
    height:'100vh'
  },
  mainSm: {
    backgroundColor: 'rgb(242, 243, 248)',
    position: "fixed",
    textAlign: 'center',
    paddingTop: '15%',
    height:'100vh'

  },
  para: {
    fontSize: "25px",
    color: "black",
    letterSpacing: "2px",
    fontWeight: "300",
    textAlign: "center",
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
  },
  paraSm: {
    marginTop: '0',
    fontSize: "16px",
    color: "black",
    letterSpacing: "0",
    fontWeight: "200",
    textAlign: "center",
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
  },
  heading: {
    color: "black",
    fontWeight: "500",
    fontSize: "40px",
    textAlign: "center",
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
  },
  headingSm: {
    color: "black",
    fontWeight: "500",
    fontSize: "20px",
    textAlign: "center",
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
  },
  link : {
    textDecoration: 'none',
  },
  button:{
    marginTop:'30px',
    fontSize:'14px',
  },
  buttonSm:{
    marginTop:"30px",
    fontSize:'10px'
  }
};
