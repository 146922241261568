import { PEP, cityOfBirth, countries, dualNationality, typesOfAddress } from "../../../user/component/bankDetail/constant";

export const getSteps = () => {
  return [
    "Basic Info",
    "Education",
    "Bank Account",
    "Documents",
    "Social Account",
    "Agreement",
  ];
};

export const stateData = {
  fullName: "",
  fatherName: "",
  email: "",
  DateOfBirth: "",
  phoneNumber: "",
  idCardNumber: "",
  martialStatus: "",
  status: "onboarding",
  gender: "",
  address: "",
  education: "",
  experience: "",
  idCardFront: "",
  idCardBack: "",
  profilephoto: "",
  facebook: "",
  github: "",
  linkedIn: "",
  skype: "",
  timestamp: "",
  accountName: "HBL",
  accountHolderName: "",
  accountType: "",
  accountBranchCode: "",
  accountIbn: "",
  accountNumber: "",
  nationality: countries[0].label,
  dualNational: dualNationality[0],
  typeOfAddress: typesOfAddress[0],
  countryOfBirth: countries[0].label,
  cityofBirth: cityOfBirth[0].label,
  countryOfResidence: countries[0].label,
  nicIssueDate: "",
  nicExpiryDate: "",
  customerVisuallyImpaired: dualNationality[0],
  motherMaidenName: "",
  citizenTaxRasidenceOtherThanPakistan: dualNationality[0],
  husbandName: "",
  pep: PEP[0],
  permanentCity: cityOfBirth[0].label,
  nextOfKinName: "",
  nextOfKinRelation: "",
  nextOfKinPhoneNumber: "",
  onboardingAt: new Date(),
  sign: "",
  contractType: "",
  employmentType: "",
  id: "",
};
