import React, { useEffect, useRef, useState } from "react";
import { style } from "./style.js";
import { Button, Grid, Paper } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { emailValid } from "../../utils";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import { Gender, MartialStatus } from "./constant.js";

const BasicInformation = ({ state, setState, handleNext }) => {
  const {
    fullName,
    fatherName,
    email,
    DateOfBirth,
    phoneNumber,
    idCardNumber,
    gender,
    martialStatus,
    address,
  } = state;

  const dateOfBirthRef = useRef(null);
  const [inpType, setInpType] = useState("text");
  const validate = () => {
    if (!fullName) {
      toast.error("Full Name Required ");
    }
    if (!fatherName) {
      toast.error("Father Name  Required");
    }
    if (!email) {
      toast.error("Email Required");
    }
    if (!DateOfBirth) {
      toast.error("Date of Birth Required");
    }
    if (!phoneNumber) {
      toast.error("Mobile Number Required");
    }
    if (!idCardNumber) {
      toast.error("IDCard Number Required");
    }
    if (!gender) {
      toast.error("Gender Required");
    }
    if (!martialStatus) {
      toast.error("Marital Status Required");
    }
    if (!address) {
      toast.error("Address Required");
    }

    if (email && !emailValid(email)) {
      toast.error("Enter a valid email");
    }
    if (phoneNumber && phoneNumber.length !== 12) {
      toast.error("Invalid Mobile Number e.g. 03xx-xxxxxxx");
    }
    if (idCardNumber && idCardNumber.length !== 15) {
      toast.error("Invalid CNIC e.g. 42101-1111111-1");
    }

    if (
      !fullName ||
      !fatherName ||
      !email ||
      !emailValid(email) ||
      !DateOfBirth ||
      !phoneNumber ||
      phoneNumber.length !== 12 ||
      !idCardNumber ||
      idCardNumber.length !== 15 ||
      !gender ||
      !martialStatus ||
      !address
    ) {
      return;
    } else return "ok";
  };

  const selectGender = (val) => {
    setState({ gender: val });
  };
  const selectMartialStatus = (val) => {
    setState({ martialStatus: val });
  };
  useEffect(() => {
    if (dateOfBirthRef?.current) {
      if (dateOfBirthRef?.current?.type === "date") {
        dateOfBirthRef?.current?.showPicker();
      }
    }
  }, [inpType]);

  const onSubmit = () => {
    if (validate() === "ok") {
      handleNext();
    }
  };
  return (
    <Paper style={style.paper}>
      <div style={style.basicInputs}>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <TextField
              style={style.input}
              required
              type="text"
              label="Full Name"
              value={fullName}
              onChange={(e) => setState({ fullName: e.target.value })}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              style={style.input}
              required
              type="text"
              label="Father Name"
              value={fatherName}
              onChange={(event) => {
                setState({ fatherName: event.target.value });
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              disabled
              style={style.input}
              required
              type="email"
              label="Email"
              value={email}
              onChange={(event) => {
                setState({ email: event.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <TextField
              style={style.inputDate}
              required
              inputRef={dateOfBirthRef}
              label="Date of Birth"
              type={inpType}
              value={DateOfBirth}
              onFocus={() => {
                setInpType("date");
              }}
              onBlur={() => {
                setInpType("text");
              }}
              onChange={(e) => setState({ DateOfBirth: e.target.value })}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <InputMask
              maskChar={0}
              mask="9999-9999999"
              value={phoneNumber}
              onChange={(e) => setState({ phoneNumber: e.target.value })}
            >
              {(props) => (
                <TextField
                  required
                  {...props}
                  style={style.input}
                  label="Phone Number"
                  placeholder="Mobile number e.g. 03xx-xxxxxxx"
                />
              )}
            </InputMask>
          </Grid>
          <Grid item md={4} xs={12}>
            <InputMask
              maskChar={0}
              mask="99999-9999999-9"
              value={idCardNumber}
              onChange={(e) => setState({ idCardNumber: e.target.value })}
            >
              {(props) => (
                <TextField
                  {...props}
                  style={style.input}
                  required
                  label="CNIC"
                  placeholder="CNIC e.g. 42101-1111111-1"
                />
              )}
            </InputMask>
          </Grid>
        </Grid>
       
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Autocomplete
              value={gender}
              onChange={(event, newValue) => {
                selectGender(newValue ? newValue: "");
              }}
              options={Gender}
              renderInput={(params) => (
                <TextField
                required
                  {...params}
                  label="Select Gender"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Autocomplete
              value={martialStatus}
              onChange={(event, newValue) => {
                selectMartialStatus(newValue ? newValue : "");
              }}
              options={MartialStatus}
              renderInput={(params) => (
                <TextField
                required
                  {...params}
                  label="Select Martial Status"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              style={style.input}
              required
              type="text"
              label="Address"
              value={address}
              onChange={(e) => setState({ address: e.target.value })}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ position: "relative" }}>
            <Button
              variant={"contained"}
              color={"primary"}
              style={style.NextButton}
              onClick={onSubmit}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default BasicInformation;
