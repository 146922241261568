import { makeStyles } from '@material-ui/core/styles';
export const style = makeStyles((theme) => ({
  paragraph: {
    fontFamily: "Bungee Inline', cursive",
    lineHeight: 2,
    fontWeight: 400,
    textAlign: 'justify',
    textTransform: 'none',
  },
  condition: {
    fontWeight: 900,
    paddingTop: 12,
    paddingBottom: 12,
  },
  box: {
    border: '1px solid black',
    padding: '10px',
    marginBottom: '2%',
  },
  uploadButton: {
    marginRight: '8px',
  },
  buttonDiv: {
    position: 'relative',
    marginBottom: '200px',
    marginTop: '20px',
  },
  NextButton: {
    float: 'right',
    position: 'relative',
    right: 0,
    top: '50%',
  },
}));
