import React from "react";
import { style } from './style.js'

export default function StepperFooter() {
  return (
    <div >
      <p style={style.footer}> © 2023 TechloSet onboarding! All Rights Reserved</p>
    </div>
  );
}
