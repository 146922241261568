import React, { useState } from "react";
import { style } from "./style.js";
import { Grid, InputLabel, Menu, MenuItem, Paper } from "@material-ui/core";
import NextButton from "../../../../commonComponents/buttons/Next/index.js";
import BackButton from "../../../../commonComponents/buttons/BackButton/BackButton";
import { toast } from "react-toastify";

const EducationExperience = ({ state, setState, handleNext, handleBack }) => {
  const { education, experience } = state;
  const [eduMenu, setEduMenu] = useState(null)
  const [expMenu, setExpMenu] = useState(null)
  const [eduItems, setEduItems] = useState({
    eduOption1: null,
    eduOption2: null,
    eduOption3: null,
    eduOption4: null,
    eduOption5: null,
  })
  const [ongoingOptions, setOngoingOptions] = useState({
    inter: null,
    bachlor: null,
    master: null,
    phd: null,
  })

  const { eduOption1, eduOption2, eduOption3, eduOption4, eduOption5 } = eduItems
  const { inter, bachlor, master, phd } = ongoingOptions

  const validate = () => {
    if (!education) { toast.error("Education Required "); }
    if (!experience) { toast.error("Experience Required"); }
    if (
      !education ||
      !experience
    ) { return }
    else return "ok";
  };

  const onSubmit = () => {
    if (validate() === "ok") {
      handleNext();
    }
  };
  const eduMenuClose = () => {
    setEduMenu(null)
  }
  const selectEduItem = (val) => {
    setState({ education: val });
    eduMenuClose()
    closeEduItemMenu()
    closeOngoingOptions()
  }
  const expMenuClose = () => {
    setExpMenu(null)
  }
  const selectExpItem = (val) => {
    setState({ experience: val });
    expMenuClose()
  }
  const closeEduItemMenu = () => {
    setEduItems({
      eduOption1: null,
      eduOption2: null,
      eduOption3: null,
      eduOption4: null,
      eduOption5: null,
    })
  }
  const closeOngoingOptions = () => {
    setOngoingOptions({
      inter: null,
      bachlor: null,
      master: null,
      phd: null
    })
  }

  return (
    <Paper style={style.paper}>
      <div style={style.educationInputs}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <InputLabel style={style.label}><strong>Select Education</strong></InputLabel>
            <div style={style.select}>
              <MenuItem onClick={(e) => { setEduMenu(e.currentTarget) }}>{education ? education : "Select"}</MenuItem>
              {
                <Menu
                  id="simple-menu"
                  anchorEl={eduMenu}
                  keepMounted
                  open={Boolean(eduMenu)}
                  onClose={eduMenuClose}
                >
                  <MenuItem onClick={(e) => { setEduItems({ eduOption1: e.currentTarget }) }}>Matric</MenuItem>
                  {
                    <Menu
                      id="simple-menu"
                      anchorEl={eduOption1}
                      keepMounted
                      open={Boolean(eduOption1)}
                      onClose={closeEduItemMenu}
                    >
                      <MenuItem onClick={() => { selectEduItem('Matric (OnGoing)') }}>OnGoing</MenuItem>
                      <MenuItem onClick={() => { selectEduItem('Matric (Completed)') }}>Completed</MenuItem>
                    </Menu>
                  }
                  <MenuItem onClick={(e) => { setEduItems({ eduOption2: e.currentTarget }) }}>Intermediate</MenuItem>
                  {
                    <Menu
                      id="simple-menu"
                      anchorEl={eduOption2}
                      keepMounted
                      open={Boolean(eduOption2)}
                      onClose={closeEduItemMenu}
                    >
                      <MenuItem onClick={(e) => { setOngoingOptions({ inter: e.currentTarget }) }}>OnGoing</MenuItem>
                      {
                        <Menu
                          id="simple-menu"
                          anchorEl={inter}
                          keepMounted
                          open={Boolean(inter)}
                          onClose={closeOngoingOptions}
                        >
                          <MenuItem onClick={() => { selectEduItem('Intermediate (OnGoing 1st Year)') }}>1st Year</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('Intermediate (OnGoing 2nd Year)') }}>2nd Year</MenuItem>
                        </Menu>
                      }
                      <MenuItem onClick={() => { selectEduItem('Intermediate (Completed)') }}>Completed</MenuItem>
                    </Menu>
                  }
                  <MenuItem onClick={(e) => { setEduItems({ eduOption3: e.currentTarget }) }}>Bachelor</MenuItem>
                  {
                    <Menu
                      id="simple-menu"
                      anchorEl={eduOption3}
                      keepMounted
                      open={Boolean(eduOption3)}
                      onClose={closeEduItemMenu}
                    >
                      <MenuItem onClick={(e) => { setOngoingOptions({ bachlor: e.currentTarget }) }}>OnGoing</MenuItem>
                      {
                        <Menu
                          id="simple-menu"
                          anchorEl={bachlor}
                          keepMounted
                          open={Boolean(bachlor)}
                          onClose={closeOngoingOptions}
                        >
                          <MenuItem onClick={() => { selectEduItem('Bachelor (OnGoing 1st Semester)') }}>1st Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('Bachelor (OnGoing 2nd Semester)') }}>2nd Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('Bachelor (OnGoing 3rd Semester)') }}>3rd Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('Bachelor (OnGoing 4th Semester)') }}>4th Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('Bachelor (OnGoing 5th Semester)') }}>5th Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('Bachelor (OnGoing 6th Semester)') }}>6th Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('Bachelor (OnGoing 7th Semester)') }}>7th Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('Bachelor (OnGoing 8th Semester)') }}>8th Semester</MenuItem>
                        </Menu>
                      }
                      <MenuItem onClick={() => { selectEduItem('Bachelor (Completed)') }}>Completed</MenuItem>
                    </Menu>
                  }
                  <MenuItem onClick={(e) => { setEduItems({ eduOption4: e.currentTarget }) }}>Master</MenuItem>
                  {
                    <Menu
                      id="simple-menu"
                      anchorEl={eduOption4}
                      keepMounted
                      open={Boolean(eduOption4)}
                      onClose={closeEduItemMenu}
                    >
                      <MenuItem onClick={(e) => { setOngoingOptions({ master: e.currentTarget }) }}>OnGoing</MenuItem>
                      {
                        <Menu
                          id="simple-menu"
                          anchorEl={master}
                          keepMounted
                          open={Boolean(master)}
                          onClose={closeOngoingOptions}
                        >
                          <MenuItem onClick={() => { selectEduItem('Master (OnGoing 1st Semester)') }}>1st Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('Master (OnGoing 2nd Semester)') }}>2nd Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('Master (OnGoing 3rd Semester)') }}>3rd Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('Master (OnGoing 4th Semester)') }}>4th Semester</MenuItem>
                        </Menu>
                      }
                      <MenuItem onClick={() => { selectEduItem('Master (Completed)') }}>Completed</MenuItem>
                    </Menu>
                  }
                  <MenuItem onClick={(e) => { setEduItems({ eduOption5: e.currentTarget }) }}>PhD</MenuItem>
                  {
                    <Menu
                      id="simple-menu"
                      anchorEl={eduOption5}
                      keepMounted
                      open={Boolean(eduOption5)}
                      onClose={closeEduItemMenu}
                    >
                      <MenuItem onClick={(e) => { setOngoingOptions({ phd: e.currentTarget }) }}>OnGoing</MenuItem>
                      {
                        <Menu
                          id="simple-menu"
                          anchorEl={phd}
                          keepMounted
                          open={Boolean(phd)}
                          onClose={closeOngoingOptions}
                        >
                          <MenuItem onClick={() => { selectEduItem('PhD (OnGoing 1st Semester)') }}>1st Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('PhD (OnGoing 2nd Semester)') }}>2nd Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('PhD (OnGoing 3rd Semester)') }}>3rd Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('PhD (OnGoing 4th Semester)') }}>4th Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('PhD (OnGoing 5th Semester)') }}>5th Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('PhD (OnGoing 6th Semester)') }}>6th Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('PhD (OnGoing 7th Semester)') }}>7th Semester</MenuItem>
                          <MenuItem onClick={() => { selectEduItem('PhD (OnGoing 8th Semester)') }}>8th Semester</MenuItem>
                        </Menu>
                      }
                      <MenuItem onClick={() => { selectEduItem('PhD (Completed)') }}>Completed</MenuItem>
                    </Menu>
                  }
                </Menu>
              }
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <InputLabel style={style.label}><strong>Experience</strong></InputLabel>
            <div
              style={style.select}
            >
              <MenuItem onClick={(e) => { setExpMenu(e.currentTarget) }}>{experience ? experience : "Select"}</MenuItem>
              {
                <Menu
                  id="simple-menu"
                  anchorEl={expMenu}
                  keepMounted
                  open={Boolean(expMenu)}
                  onClose={expMenuClose}
                >
                  <MenuItem onClick={() => { selectExpItem("Beginner") }}>Beginner</MenuItem>
                  <MenuItem onClick={() => { selectExpItem("One Year") }}>One Year</MenuItem>
                  <MenuItem onClick={() => { selectExpItem("Two Years") }}>Two Years</MenuItem>
                  <MenuItem onClick={() => { selectExpItem("Three Years") }}>Three Years</MenuItem>
                  <MenuItem onClick={() => { selectExpItem("Four Years") }}>Four Years</MenuItem>
                  <MenuItem onClick={() => { selectExpItem("Five Years") }}>Five Years</MenuItem>
                  <MenuItem onClick={() => { selectExpItem("Five Years Plus") }}>Five Years Plus</MenuItem>
                </Menu>
              }
            </div>
          </Grid>
          <Grid item md={12} xs={12} style={{ position: "relative" }}>
            <BackButton
              variant="contained"
              color="primary"
              component="span"
              text="Back"
              handleBack={handleBack}
            />
            <NextButton
              variant="contained"
              color="primary"
              onSubmit={onSubmit}
              text="Next"
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default EducationExperience;
