import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import { toast } from "react-toastify";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Steppers from "./components/Steppers/Steppers";
import StepperHeader from "../../commonComponents/stepperheader";
import StepperFooter from "../../commonComponents/stepperfooter";
import db from "../../config/firebase";
import { useHistory, useParams } from "react-router-dom";

export default function ONBoarding() {
  const [userDetails, setUserDetails] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [supportedBanks, setSupportedBanks] = useState([]);
  const [otherDetails, setOtherDetails] = useState(null);

  const { userName, userEmail, contractType } = useParams();

  useEffect(() => {
    if (userEmail && contractType && userName) {
      setEmail(userEmail);
      setOtherDetails({
        fullName: userName,
        contractType,
      });
    }
  }, [userName, userEmail, contractType]);

  const style = {
    emailContainer: {
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
    },

    formControlWidth: { width: "100%" },
  };
  const history = useHistory();
  const getDetails = async (email) => {
    try {
      setLoading(true);
      const userData = await db
        .collection("Onboarding")
        .where("email", "==", `${email.toLowerCase()}`)
        .get();
      let userDetails = [];
      userData.forEach((item) => {
        userDetails.push({ docId: item.id, ...item.data() });
      });
      if (userDetails?.length === 0) {
      let Jobs = await db
        .collection("JobApplication")
        .where("email", "==", `${email.toLowerCase()}`)
        .get();
      let details = [];
      Jobs.forEach((item) => {
        details.push({ docId: item.id, ...item.data() });
      });

      if (details.length > 0) {
        let userdata = await details[details.length - 1];
        setUserDetails(userdata);
      }
      const Banks = await db.collection("SupportedBanks").get();
      let sBanks = [];
      Banks.forEach((item) => {
        sBanks.push({ ...item.data() });
      });
      setSupportedBanks(sBanks);
      setLoading(false);
      } else {
        toast.error("Already Onboarded!");
        history.push("/");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  const checkDetails = () => {
    if (email === "") {
      toast.error("Email is Required");
    } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      toast.error("Enter a valid email");
    } else {
      getDetails(email);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container fixed>
        <StepperHeader />
        {loading ? (
          <Grid container style={style.emailContainer}>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl style={style.formControlWidth} required>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="next"
                        onClick={checkDetails}
                        color="primary"
                      >
                        <ArrowForwardIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <Steppers
            data={userDetails}
            supportedBanks={supportedBanks}
            otherInfo={{ ...otherDetails, email }}
          />
        )}
        <StepperFooter />
      </Container>
    </React.Fragment>
  );
}
