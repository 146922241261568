export const style={
footer :{
  fontSize:'12px',
  position: 'fixed',
  left: '0',
  bottom: '0',
  width: '100%',
  backgroundColor: '#3f51b5',
  color: 'white',
  textAlign: 'center',
  margin: '0px',
  padding: '8px',
}
}