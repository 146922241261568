import { Button } from "@material-ui/core";
import React from "react";
import { style } from "./style.js";
import { useHistory } from "react-router-dom";
import db from "../../../config/firebase.js";
import axiosTechloset from "../../../config/axiosTechloset";
import { toast } from "react-toastify";
import moment from "moment";
export default function NextButton({
  variant,
  color,
  onSubmit,
  text,
  loader,
  state,
  setLoader,
}) {
  const history = useHistory();

  const handleFinish = async () => {
    try {
      setLoader(true);
      const { id, contractType, ...other } = state;
      let email = other.email.toLowerCase();
      let fullName = other?.fullName?.split("+").join(" ");
      let employStatusHistory = [
        {
          date: moment(other?.onboardingAt)?.format("MMMM Do YYYY, h:mm a"),
          status: other.status,
        },
      ];
      let payload = { ...other, email, fullName, employStatusHistory };
      if (contractType) {
        payload = { ...payload, contractType };
      }

      // const userData = await db
      //   .collection("Onboarding")
      //   .where("email", "==", `${email.toLowerCase()}`)
      //   .get();
      // let userDetails = [];
      // userData.forEach((item) => {
      //   userDetails.push({ docId: item.id, ...item.data() });
      // });

      // if (userDetails?.length === 0) {
      await db.collection("Onboarding").add(payload);
      await axiosTechloset.post(`/slack/onboarding`, {
        name: other?.fullName?.split("+").join(" "),
        email: other?.email,
        github: other?.github,
        number: other?.phoneNumber,
      });
      if (id && id !== "") {
        await db
          .collection("JobApplication")
          .doc(id)
          .update({ status: `${other.status}` });
      }
      // Remove data from localStorage
      localStorage.removeItem("steppersData");
      history.push("/thanks");
      // } else {
      //   toast.error("Data already Exist");
      // }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Button
      disabled={loader}
      variant={variant}
      color={color}
      style={style.NextButton}
      onClick={text === "Finish" ? handleFinish : onSubmit}
    >
      {text}
    </Button>
  );
}
