import React from "react";
import { Box, Button, Modal } from "@material-ui/core";
import { Cropper, CropperRef } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { useStyles } from "./style";
import avatar from "../../../../../assets/avatar.jpg";
export default function ImageCrop({
  src,
  open,
  handleClose,
  onDone,
  cropperRef,
  cropData,
  onPreview,
}) {
  const Classes = useStyles();
  return (
    <div>
      <Modal open={open} size={"3xl"}>
        <Box className={Classes.imageCropContainer}>
          <Box className={Classes.cropImageContainer}>
            <Cropper
              ref={cropperRef}
              // className="example__cropper"
              backgroundClassName="example__cropper-background"
              src={src}
              className={Classes.cropModalImage}
            />
            <br />
          </Box>

          <Box className={Classes.previewContainer}>
            {cropData ? (
              <>
                <Box>
                  <img src={cropData} className={Classes.previewImage} />
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <img src={avatar} className={Classes.previewdummyImage} />
                </Box>
              </>
            )}
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
              }}
            >
              <Button variant="contained" color={"primary"} onClick={onPreview}>
                Preview
              </Button>
              <br />
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button variant="contained" color={"primary"} onClick={onDone}>
                  Done
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
