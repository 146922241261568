export const style = {
  disabledButton: {
    float: 'right',
    position: 'relative',
    right: 0,
    top: '50%',
  },
  basicInputs: {
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  input: {
    width: '100%',
    outline: 'none',
  },
  inputDate: {
    width: '100%',
    color: 'grey',
    outline: 'none',
  },
  select: {
    width: '100%',
    border: "1px solid #d7dde2",
    borderRadius: "5px",
    padding: '10px',
    marginTop: '10px',
    outline: 'none',
    color: 'grey',
    backgroundColor: 'rgb(255 255 255)'
  },
  paper: {
    padding: '10px',
  },
  NextButton: {
    float: 'right',
    position: 'relative',
    right: 0,
    top: '50%',
  },
} 
