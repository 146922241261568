import React from "react";
import { style } from "./style.js";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import thanklogo from '../../assets/thanku.jpg';
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

function Thanks() {
  const matches = useMediaQuery("(min-width:960px)");
  return (
    <div style={matches ? style.main : style.mainSm}>
      <Grid container>
        <Grid item md={3} xs={3} lg={3} ></Grid>
        <Grid item md={6} xs={12} lg={6} style={style.alignment}>
          <img
            src={thanklogo}
            width={matches ? "70%" : "60%"}
            height={matches ? "50%" : "60%"}
            alt="logo"
          />
          <Typography
            style={matches ? style.heading : style.headingSm}
            variant="h3" >
                Submission  Successful
            </Typography>
            <Typography
            style={matches ? style.heading : style.headingSm}
            variant="h3" >
                You're On Board! 
            </Typography>
          <Typography
            style={matches ? style.para : style.paraSm} variant="subtitle1" gutterBottom >
         You'll be notified about your Application Status!
                        Thank you!
          </Typography>
          <Link to="/" style={style.link} >
            <Button variant="contained" size="large" color="primary">
              Back to Home
          </Button>
          </Link>
          <br />
        </Grid>
        <Grid item md={3} sm={3} xs={3} lg={3}></Grid>
      </Grid>
    </div>
  );
}
export default Thanks;
