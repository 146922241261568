import React from "react";
import { style } from "./style.js";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";

export default function Navbar(props) {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <AppBar
      style={{ ...style.main, backgroundColor: props.color ? props.color : "transparent" }} position="fixed">
      <Toolbar style={style.items}>
        <Link to="/">
          <img style={matches ? style.imglogo : style.imglogo_Sm} src={logo} alt="logo"/>
        </Link>
        <Typography variant="h6" style={style.title}></Typography>
        <div style={style.icondiv}>
          <a target="_blank" href="https://web.facebook.com/techloset/?modal=admin_todo_tour" style={matches ? style.toplink : style.toplink_Sm}>
            <FacebookIcon style={matches ? style.icons : style.icons_Sm} />
          </a>
          <a target="_blank" href="https://twitter.com/techloset" style={matches ? style.toplink : style.toplink_Sm}>
            <TwitterIcon style={matches ? style.icons : style.icons_Sm} />
          </a>
          <a target="_blank" href="https://www.youtube.com/channel/UCPC0RXZrvh50scjAzJC-IWg?view_as=subscriber" style={matches ? style.toplink : style.toplink_Sm}>
            <YouTubeIcon style={matches ? style.icons : style.icons_Sm} />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/techloset/about/" style={matches ? style.toplink : style.toplink_Sm}>
            <LinkedInIcon style={matches ? style.icons : style.icons_Sm} />
          </a>
        </div>
      </Toolbar>
    </AppBar>
  );
}
