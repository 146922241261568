/* eslint-disable no-useless-escape */
import React from "react";
import { style } from "./style.js";
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import BackButton from "../../../../commonComponents/buttons/BackButton/BackButton";
import { toast } from "react-toastify";
import { fbValid, linkedInValid, githubValid } from "../../utils"

const SocailMediaAccount = ({ state, setState, handleBack, handleNext }) => {
  const { facebook, github, linkedIn, skype } = state;

  const validate = () => {
    if (!facebook) { toast.error("Facebook profile link Required"); }
    if (!github) { toast.error("Github profile link Required"); }
    if (!linkedIn) { toast.error("LinkedIn profile link Required"); }
    if (!skype) { toast.error("Skype profile link Required"); }
    if (facebook && !fbValid(facebook)) { toast.error("Enter valid Facebook profile link e.g. https://web.facebook.com/techloset"); }
    if (github && !githubValid(github)) { toast.error("Enter valid github profile link e.g. https://github.com/techloset"); }
    if (linkedIn && !linkedInValid(linkedIn)) { toast.error("Enter valid linkedIn profile link e.g. https://www.linkedin.com/company/techloset"); }

    if (
      !facebook ||
      !github ||
      !linkedIn ||
      !skype ||
      !fbValid(facebook) ||
      !githubValid(github) ||
      !linkedInValid(linkedIn)
    ) { return }

    else return "ok";
  };

  const onSubmit = () => {
    if (validate() === "ok") {
      handleNext();
    }
  };

  return (
    <Paper style={style.paper}>
      <div style={style.socialInputs}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              style={style.input}
              type="text"
              placeholder="Facebook e.g. https://web.facebook.com/techloset"
              value={facebook}
              onChange={(e) => setState({ facebook: e.target.value })}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              style={style.input}
              type="text"
              placeholder="Github e.g. https://github.com/techloset"
              value={github}
              onChange={(e) => setState({ github: e.target.value })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              style={style.input}
              type="text"
              placeholder="LinkedIn e.g. https://www.linkedin.com/company/techloset"
              value={linkedIn}
              onChange={(e) => setState({ linkedIn: e.target.value })}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              style={style.input}
              type="text"
              placeholder="Skype e.g. live:rana.naveed812"
              value={skype}
              onChange={(e) => setState({ skype: e.target.value })}
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ position: "relative" }}>
            <BackButton
              variant="contained"
              color="primary"
              component="span"
              text="Back"
              handleBack={handleBack}
            />
            <Button
              variant="contained"
              color="primary"
              style={style.NextButton}
              onClick={onSubmit}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default SocailMediaAccount;
