import "./App.css";
import Routing from "./routing/Routing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
require("dotenv").config();

function App() {
  return (
    <div className="App">
      <Routing />
      <ToastContainer />
    </div>
  );
}

export default App;
