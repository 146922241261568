import img2 from "../../assets/bgimage2.jpg";
export const style = {
  overly: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  bgImage: {
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    maxWidth: "100%",
    height: " 100vh",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundImage: `url(${img2})`,
  },
  para: {
    fontSize: "20px",
    color: "rgb(255, 255, 255)",
    letterSpacing: "2px",
    fontWeight: "500",
    lineHeight: "30px",
    textAlign: "center",
    fontFamily: "some_font",
  },
  para_Sm: {
    fontSize: "15px",
    color: "rgb(255, 255, 255)",
    letterSpacing: "2px",
    fontWeight: "300",
    lineHeight: "30px",
    textAlign: "center",
  },
  heading: {
    marginTop: "160px",
    color: "#fff",
    fontWeight: "500",
    fontSize: "40px",
    marginBottom: "6px",
    textAlign: "center",
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
  },
  heading_Sm: {
    marginTop: "100px",
    color: "#fff",
    fontWeight: "700",
    fontSize: "20px",
    marginBottom: "6px",
    textAlign: "center",
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
  },
  alignment: {
    margin: 'auto',
    textAlign: 'center',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  alignment_Sm: {
    textAlign: 'center',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};