export const style = {
  socialInputs: {
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  input: {
    width: '100%',
    // border: "1px solid #d7dde2",
    // borderRadius: "5px",
    padding: '10px',
    outline: 'none',
  },
  paper: {
    padding: '10px',
  },
  NextButton: {
    float: 'right',
    position: 'relative',
    right: 0,
    top: '50%',
  },
}