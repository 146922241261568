import React, { useEffect, useState } from "react";
import { style, BorderLinearProgress, StyledStepbar } from "./steppersStyle";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { getSteps, stateData } from "./getSteps";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { styled } from "@material-ui/styles";
import getStepContent from "./getStepContent.js";
import { useObjectState } from "../../../../commonUtils/commonState";
export default function Steppers({ data, supportedBanks, otherInfo }) {
  const matches2 = useMediaQuery("(min-width:964px)");
  const [state, setState] = useObjectState({ ...stateData });
  const [selectedPaper, setSelectedPaper] = useState("first");

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("steppersData"));
    if (
      (savedData && savedData?.email === data?.email) ||
      savedData?.email === otherInfo?.email
    ) {
      setState(savedData);
      setSelectedPaper(savedData.selectedPaper || 'first');
    } else {
      if (data !== null) {
        setState({
          fullName: data?.fname + " " + data?.lname || "",
          fatherName: data?.faname || "",
          address: data?.addressOne || "",
          email: data?.email || "",
          skype: data?.skype || "",
          github: data?.gitHub || "",
          id: data?.docId || "",
          contractType: data?.contractType || "",
          selectedPaper: "first",
        });
      } else if (otherInfo !== null) {
        setState({
          fullName: otherInfo?.fullName || "",
          email: otherInfo?.email || "",
          contractType: otherInfo?.contractType || "",
          selectedPaper: "first",
        });
      }
    }
  }, [data, otherInfo]);

  useEffect(() => {
    localStorage.setItem(
      "steppersData",
      JSON.stringify({ ...state, selectedPaper })
    );
  }, [state, selectedPaper]);

  const [activeStep, setActiveStep] = React.useState(0);

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const StyledStepLabel = styled(StepLabel)({
    "& .MuiStepLabel-label ": {
      fontSize: matches2 ? "18px" : "12px",
      padding: "auto",
    },
  });

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div style={matches2 ? style.root : style.rootSm}>
      <StyledStepbar>
        <Stepper style={style.steper} activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StyledStepLabel>{label}</StyledStepLabel>
            </Step>
          ))}
        </Stepper>
      </StyledStepbar>
      <BorderLinearProgress
        variant="determinate"
        value={((activeStep + 1) / steps.length) * 100}
      />
      <div>
        <div>
          {getStepContent(
            activeStep,
            state,
            setState,
            selectedPaper,
            setSelectedPaper,
            handleNext,
            handleBack,
            supportedBanks
          )}
        </div>
      </div>
    </div>
  );
}
