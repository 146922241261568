import React from "react";
import BasicInformation from "../../../user/component/basicInfo/BasicInformation";
import EducationExperince from "../../../user/component/educationExperince/EducationExperience";
import Document from "../../../user/component/document/Document";
import SocailMediaAccount from "../../../user/component/socailMediaAccount/SocialMediaAccount";
import Agreement from "../../../user/component/agreement/Agreement";
import BankDetail from "../../../user/component/bankDetail/bankDetail";

export default function getStepContent(
  activeStep,
  state,
  setState,
  selectedPaper,
  setSelectedPaper,
  handleNext,
  handleBack,
  supportedBanks,
) {
  switch (activeStep) {
    case 0:
      return (
        <BasicInformation // Agreement
          state={state}
          setState={setState}
          handleNext={handleNext}
        />
      );
    case 1:
      return (
        <EducationExperince
          state={state}
          setState={setState}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      );
    case 2:
      return (
        <BankDetail
          state={state}
          setState={setState}
          selectedPaper={selectedPaper}
          setSelectedPaper={setSelectedPaper}
          handleNext={handleNext}
          handleBack={handleBack}
          supportedBanks={supportedBanks}
        />
      );
    case 3:
      return (
        <Document
          state={state}
          setState={setState}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      );
    case 4:
      return (
        <SocailMediaAccount
          state={state}
          setState={setState}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      );
    case 5:
      return (
        <Agreement state={state} setState={setState} handleBack={handleBack} />
      );
    default:
      return "Unknown stepIndex";
  }
}
