export const style = {
  main: {
    backgroundColor: "transparent",
    boxShadow: "none",
    opacity: "0.9",
    padding: '0px',
    margin: '0px',
  },
  imglogo: {
    marginTop: '8px',
    maxWidth: "40%",
    height: "auto",
    marginLeft: "40px",
  },
  imglogo_Sm: {
    marginTop: '10px',
    maxWidth: "70%",
    height: "auto",
    marginLeft: "15px",
  },
  title: {
    flexGrow: 1,
  },
  toplink: {
    paddingTop: "10px",
    marginBottom: "10px",
    paddingRight: '25px',
  },
  toplink_Sm: {
    paddingTop: "10px",
    marginBottom: "10px",
    paddingRight: '15px',
  },
  items: {
    padding: '0'
  },
  icondiv: {
    display: 'flex',
    alignItems: 'center',
    paddingHorizontal: '20px',
  },
  icons: {
    color: '#27F5E1',
  },
  icons_Sm: {
    color: '#27F5E1',
    fontSize: '1.4rem',
  },
};
