import { styled, withStyles } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import StepLabel from "@material-ui/core/StepLabel";

export const StyledStepbar = styled(StepLabel)({
  "& .MuiStepConnector-lineHorizontal": {
    borderTopStyle: "none",
  },
});

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 3,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export const style = {
  root: {
    width: "100%",
    marginTop: "100px",
  },
  rootSm: {
    width: "100%",
    marginTop: "60px",
  },
  steper: {
    overflow: "auto",
  },
  formWidth: {
    width: "100%",
  },
};
